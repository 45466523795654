import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const WandMagicSparklesIcon: FunctionComponent<SvgIconProps> = ({
  sx,
  ...props
}) => (
  <SvgIcon {...props} viewBox="0 0 15 15" sx={sx}>
    <path d="M6.39844 1.92566L6.78125 0.886597C6.80859 0.804565 6.89062 0.749878 7 0.749878C7.08203 0.749878 7.16406 0.804565 7.19141 0.886597L7.57422 1.92566L8.61328 2.30847C8.69531 2.33582 8.75 2.41785 8.75 2.49988C8.75 2.60925 8.69531 2.69128 8.61328 2.71863L7.57422 3.10144L7.19141 4.11316C7.16406 4.19519 7.08203 4.24988 7 4.24988C6.89062 4.24988 6.80859 4.19519 6.78125 4.11316L6.39844 3.10144L5.38672 2.71863C5.30469 2.69128 5.25 2.60925 5.25 2.49988C5.25 2.41785 5.30469 2.33582 5.38672 2.30847L6.39844 1.92566ZM1.25781 11.578L11.6758 1.16003C12.1953 0.640503 13.0156 0.640503 13.5352 1.16003L14.4648 2.08972C14.9844 2.60925 14.9844 3.42957 14.4648 3.9491L4.04688 14.3671C3.52734 14.8866 2.70703 14.8866 2.1875 14.3671L1.25781 13.4374C0.738281 12.9178 0.738281 12.0975 1.25781 11.578ZM13.2344 3.01941L12.6055 2.3905L9.73438 5.2616L10.3633 5.8905L13.2344 3.01941ZM0.191406 3.97644L1.75 3.37488L2.32422 1.84363C2.35156 1.70691 2.48828 1.62488 2.625 1.62488C2.73438 1.62488 2.87109 1.70691 2.89844 1.84363L3.5 3.37488L5.03125 3.97644C5.16797 4.00378 5.25 4.1405 5.25 4.24988C5.25 4.3866 5.16797 4.52332 5.03125 4.55066L3.5 5.12488L2.89844 6.68347C2.87109 6.79285 2.73438 6.87488 2.625 6.87488C2.48828 6.87488 2.35156 6.79285 2.32422 6.68347L1.75 5.12488L0.191406 4.55066C0.0820312 4.52332 0 4.3866 0 4.24988C0 4.1405 0.0820312 4.00378 0.191406 3.97644ZM9.81641 10.9764L11.375 10.3749L11.9492 8.84363C11.9766 8.70691 12.1133 8.62488 12.25 8.62488C12.3594 8.62488 12.4961 8.70691 12.5234 8.84363L13.125 10.3749L14.6562 10.9764C14.793 11.0038 14.875 11.1405 14.875 11.2499C14.875 11.3866 14.793 11.5233 14.6562 11.5507L13.125 12.1249L12.5234 13.6835C12.4961 13.7928 12.3594 13.8749 12.25 13.8749C12.1133 13.8749 11.9766 13.7928 11.9492 13.6835L11.375 12.1249L9.81641 11.5507C9.70703 11.5233 9.625 11.3866 9.625 11.2499C9.625 11.1405 9.70703 11.0038 9.81641 10.9764Z" />
  </SvgIcon>
);
