import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const GraphIcon: FunctionComponent<SvgIconProps> = ({
  sx = [],
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      sx={[{ fill: "none !important" }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <path
        d="M7.53333 11H12.2M16.8667 17.3637H15C14.6319 17.3652 14.2671 17.2956 13.9267 17.1591C13.5863 17.0226 13.277 16.8218 13.0167 16.5682C12.7564 16.3147 12.5502 16.0134 12.41 15.6818C12.2699 15.3503 12.1985 14.995 12.2 14.6364V7.36366C12.1985 7.00509 12.2699 6.64977 12.41 6.3182C12.5502 5.98664 12.7564 5.68539 13.0167 5.43184C13.277 5.17828 13.5863 4.97745 13.9267 4.84093C14.2671 4.7044 14.6319 4.63488 15 4.63639H16.8667M1.93333 7.81818H6.6C7.11547 7.81818 7.53333 8.2252 7.53333 8.72727V13.2727C7.53333 13.7748 7.11547 14.1818 6.6 14.1818H1.93333C1.41787 14.1818 1 13.7748 1 13.2727V8.72727C1 8.2252 1.41787 7.81818 1.93333 7.81818ZM17.8 1H23.4C23.9155 1 24.3333 1.40701 24.3333 1.90909V7.36364C24.3333 7.86571 23.9155 8.27273 23.4 8.27273H17.8C17.2845 8.27273 16.8667 7.86571 16.8667 7.36364V1.90909C16.8667 1.40701 17.2845 1 17.8 1ZM17.8 13.7273H23.4C23.9155 13.7273 24.3333 14.1343 24.3333 14.6364V20.0909C24.3333 20.593 23.9155 21 23.4 21H17.8C17.2845 21 16.8667 20.593 16.8667 20.0909V14.6364C16.8667 14.1343 17.2845 13.7273 17.8 13.7273Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
