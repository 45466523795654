import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import type { FunctionComponent } from "react";

export const ChartNetworkRegularIcon: FunctionComponent<SvgIconProps> = (
  props,
) => {
  return (
    <SvgIcon {...props} viewBox="0 0 640 512" fill="none">
      <path d="M288 64c0 16.9-6.5 32.2-17.2 43.6l26.9 57.5c12.2-3.4 25-5.2 38.3-5.2c39 0 74.4 15.5 100.3 40.7l77.6-56.9c-1.3-5-1.9-10.3-1.9-15.7c0-35.3 28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64c-12.3 0-23.9-3.5-33.7-9.6l-77.6 56.9c9.8 19.4 15.3 41.4 15.3 64.6c0 18.5-3.5 36.2-9.9 52.5l67.3 40.4c10.7-8.1 24.1-12.9 38.5-12.9c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-3.4 .3-6.7 .8-9.9l-67.4-40.4C419 428.5 379.8 448 336 448c-71.4 0-130.6-51.9-142-120H123.3c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40H194c6.6-39.1 28.9-72.9 60.2-94.5l-26.9-57.6c-1.1 .1-2.2 .1-3.3 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64zm48 336a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
    </SvgIcon>
  );
};
